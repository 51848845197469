import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
// import { Stats } from 'webpack';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { Water } from 'three/examples/jsm/objects/Water.js';

let camera, scene, renderer;
let controls, gui, stats;
let clock;
let tl = gsap.timeline();
let ore, drills, waste, landscape, openpit, landscapeLarge, roads, facility, water;


init();
animate();


function init() {
    // Debug
    // gui = new dat.GUI();
    // const container = document.getElementById( 'container' );3
    // stats = new Stats();
    // Canvas
    const canvas = document.querySelector('canvas.webgl')
    // container.appendChild(stats.dom);

    //Clock
    clock = new THREE.Clock()

    //Sizes
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }
    //Event listener
    window.addEventListener('resize', () =>
    {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    //Renderer
    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.5;

    // Scene
    scene = new THREE.Scene()

    //Сamera
    camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 1000 )
    camera.position.set( 5, 57, -8 );
    scene.add(camera)
    //camera gui
    // const camFolder = gui.addFolder('Camera')
    // camFolder.add(camera.position, 'x').min(-100) .max(100) .listen();
    // camFolder.add(camera.position, 'y').min(-150) .max(150) .listen();
    // camFolder.add(camera.position, 'z').min(-100) .max(100) .listen();
    // camFolder.open()
    
    //Loading Manager
    const loadingManager = new THREE.LoadingManager()
    const progressBar = document.getElementById("p")
    const progressText = document.querySelector("[for=p]")

    loadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
        
    }
    loadingManager.onLoad = function ( ) {
        // console.log( 'Loading complete!');
        hideLoadingScreen();
        cameraAnimationStart();
        // setTimeout(function(){ 
        // }, 3000); 
    };

    loadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
        const progress = (itemsLoaded / itemsTotal * 100).toFixed(0)
        progressText.value = progress
        progressBar.value = progress
    };

    // Objects
    const gltfLoader = new GLTFLoader(loadingManager);
     gltfLoader.load('./landscapeSmall/landscape_small.gltf', (gltf) => {
        // const landscapeSmall = gltf.scene.getObjectByName ('terrain_small');
        // const material = new THREE.MeshStandardMaterial({map: landscapeSmall.material.map, side: THREE.FrontSide});
        // landscapeSmall.material = material;
        gltf.scene.scale.set(0.1,0.1,0.1);
        landscape = gltf.scene.getObjectByName('terrain_small');
        // const material = new THREE.MeshStandardMaterial({color: 0xffffff, side: THREE.FrontSide});
        // const instanceMesh = THREE.InstancedMesh(landscape.geometry, material, 1);
        // mesh.position.set(0,-10,0);
        // scene.add(instanceMesh);
        scene.add(gltf.scene);
       
    })
   
    gltfLoader.load('./landscapeLarge/landscape_large.gltf',(gltf) => {
        gltf.scene.scale.set(0.1,0.1,0.1);
        landscapeLarge = gltf.scene.getObjectByName('terrain_large');
        scene.add(gltf.scene);
    })
    gltfLoader.load('./openpit/openpit.gltf',(gltf) => {
        gltf.scene.scale.set(0.1,0.1,0.1);
        openpit = gltf.scene.getObjectByName('openpit');
        scene.add(gltf.scene);
    })
    gltfLoader.load('./ore/ore.gltf',(gltf) => {
        gltf.scene.scale.set(0.1,0.1,0.1);
        ore =  gltf.scene.getObjectByName( 'Ore_body' );
        scene.add(gltf.scene);
    })
    gltfLoader.load('./waste/waste.gltf',(gltf) => {
        gltf.scene.scale.set(0.1,0.1,0.1);
        waste = gltf.scene.getObjectByName('waste');
        scene.add(gltf.scene);
    })
    gltfLoader.load('./drills/drills.gltf',(gltf) => {
        gltf.scene.scale.set(0.1,0.1,0.1);
        drills = gltf.scene.getObjectByName('drills');
        const material = new THREE.MeshStandardMaterial({color: 0x838383 ,roughness: 0.5 , metalness: 0.1});
        drills.material = material
        scene.add(gltf.scene);
    })
    gltfLoader.load('./roads/roads.gltf',(gltf) => {
        gltf.scene.scale.set(0.1,0.1,0.1);
        roads = gltf.scene.getObjectByName('roads');
        scene.add(gltf.scene);
    })
    gltfLoader.load('./facility/facility.gltf',(gltf) => {
        gltf.scene.scale.set(0.1,0.1,0.1);
        facility = gltf.scene.getObjectByName('facility');
        scene.add(gltf.scene);
    })
    gltfLoader.load('./water/water.gltf',(gltf) => {
        gltf.scene.scale.set(0.1,0.1,0.1);
        // water = gltf.scene.getObjectByName('water');
        water = gltf.scene.getObjectByName ('water');
        const waterNormal =  new THREE.TextureLoader().load( './water/waternormals.jpg', function ( texture ) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set( 8, 8 );
        } );
        const material = new THREE.MeshStandardMaterial(
            {
                color: 0x001c1e, 
                side: THREE.FrontSide, 
                normalMap: waterNormal,
                roughness: 0.2
            });
        water.material = material;
         scene.add(gltf.scene);
    })
     


    // Materials
    

    // Mesh
    

    // Lights
    const sunLight = new THREE.DirectionalLight(0xffffff, 3.5)
    sunLight.position.x = -6.5
    sunLight.position.y = 8
    sunLight.position.z = 0.3
    scene.add(sunLight)

    const skylight = new THREE.AmbientLight(0xffffff, 3)
    scene.add(skylight)

    
    createSky()
    //Fog
    // scene.fog = new THREE.Fog( 0x5c6f9c, 10, 200 );


    // Controls
    controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true
    controls.minDistance = 0
    controls.maxDistance = 100
    controls.rotateSpeed = 0.3
    controls.panSpeed = 1.1
    controls.zoomSpeed = 0.5
    controls.screenSpacePanning = false;
    controls.target.set( 1, 5, 6 )
    
    //target gui
    // const targetFolder = gui.addFolder('Target')
    // targetFolder.add(controls.target, 'x').min(-100) .max(100) .listen();
    // targetFolder.add(controls.target, 'y').min(-300) .max(300) .listen();
    // targetFolder.add(controls.target, 'z').min(-300) .max(300) .listen();
    // targetFolder.open()


    //Setup interaction
    layersOnClick()
}





//Create Sky
function createSky(){
    const sky = new Sky();
    const sun = new THREE.Vector3();
    const skyOptions = {
        turbidity: 10,
        rayleigh: 0.1,
        mieCoefficient: 0.001,
        mieDirectionalG: 0.995,
        elevation: 21.3,
        azimuth: -80,
        exposure: 0.89
    };

    sky.scale.setScalar( 450000 );
    scene.add( sky );

    const uniforms = sky.material.uniforms;
    uniforms[ 'turbidity' ].value = skyOptions.turbidity;
    uniforms[ 'rayleigh' ].value = skyOptions.rayleigh;
    uniforms[ 'mieCoefficient' ].value = skyOptions.mieCoefficient;
    uniforms[ 'mieDirectionalG' ].value = skyOptions.mieDirectionalG;

    const phi = THREE.MathUtils.degToRad( 90 - skyOptions.elevation );
    const theta = THREE.MathUtils.degToRad( skyOptions.azimuth );

    sun.setFromSphericalCoords( 1, phi, theta );

    uniforms[ 'sunPosition' ].value.copy( sun );
}
function hideLoadingScreen(){
    const loadingScreen = document.getElementById( 'loading-screen' );
    loadingScreen.classList.add( 'fade-out' );
    loadingScreen.addEventListener( 'transitionend', onTransitionEnd );
}

function onTransitionEnd( event ) {

	const element = event.target;
	element.remove();
	
}
 function cameraAnimationStart(){
    tl.to(camera.position, { x: -3.2, y: 12, z: -6.4, duration: 10})
    tl.to(controls.target, {x: 5.2, y: 5, z: 11.9, duration: 10}, "-10")
 }

//Animate
function  animate() 
{
    
    // Update objects
    // stats.update();
    
    // Update Orbital Controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(animate)
}




//Setup Buttons
function layersOnClick(){
    document.getElementById('ore').onclick = function()
    {
         ore.visible = !ore.visible;
         const btn = document.getElementById('ore');
         btn.classList.toggle('off');
    }
    document.getElementById('drills').onclick = function()
    {
         drills.visible = !drills.visible;
         const btn = document.getElementById('drills');
         btn.classList.toggle('off');
    }
    document.getElementById('waste').onclick = function()
    {
         waste.visible = !waste.visible;
         water.visible = !water.visible;
         const btn = document.getElementById('waste');
         btn.classList.toggle('off');
    }
    document.getElementById('openpit').onclick = function()
    {
         openpit.visible = !openpit.visible;
         const btn = document.getElementById('openpit');
         btn.classList.toggle('off');
    }   
    document.getElementById('landscape').onclick = function()
    {
         landscape.visible = !landscape.visible;
         landscapeLarge.visible = !landscapeLarge.visible;
         roads.visible = !roads.visible;
         const btn = document.getElementById('landscape');
         btn.classList.toggle('off');
    }
    document.getElementById('facility').onclick = function()
    {
         facility.visible = !facility.visible;
         const btn = document.getElementById('facility');
         btn.classList.toggle('off');
    }  
}